<template>
  <div :class="$style.self">
    <transition-group name="snackbar-transition" tag="div">
      <v-snackbar
        v-for="(snackbar, index) in activeSnackbars"
        :key="snackbar.id"
        :value="snackbar.isActive"
        :color="snackbar.color"
        :timeout="-1"
        absolute
        right
        :style="`bottom: ${index * 60}px;`"
      >
        <v-progress-circular
          v-show="snackbar.showLoadingIcon"
          indeterminate
          size="20"
          class="mr-3"
        />
        {{ snackbar.text }}
        <template v-slot:action>
          <v-btn dark text @click="() => hideSnackbar(snackbar.id)">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: {
    ...mapGetters(['activeSnackbars']),
  },
  methods: mapMutations(['hideSnackbar']),
};
</script>

<style lang="scss" module>
.self {
  position: fixed;
  left: 0;
  right: 4px;
  bottom: 16px;

  :global(.v-snack) {
    transition: bottom 0.1s ease-out;
  }

  :global(.snackbar-transition-enter-active) {
    transition: bottom 0.1s ease-out, opacity 0.2s ease-in-out;
  }

  :global(.snackbar-transition-enter) {
    opacity: 0;
  }
}
</style>
