import Vue from 'vue';
import LayoutStackStyle from './LayoutStackStyle.css';

export default Vue.component('LayoutStack', {
  render(createElement) {
    return createElement(
      'div',
      {
        class: 'layout-stack',
        style: LayoutStackStyle,
      },
      [
        ...this.$slots.default.map(vnode => {
          return createElement(
            'div',
            {
              class: 'layout-stack__layer',
            },
            [vnode],
          );
        }),
      ],
    );
  },
});
