import axios from 'axios';
import store from '../store';
import { $modal } from '../plugins/modal-plugin';

if (!process.env.VUE_APP_API_BASE_URL) {
  throw new Error(
    'Missing required environment variable: VUE_APP_API_BASE_URL.',
  );
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(request => {
  if (store.state.authToken) {
    request.headers['OAuth-Token'] = store.state.authToken;
  }
  return request;
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (((error || {}).response || {}).status === 401 && $modal.isReady) {
      $modal
        .loadConfirmation(
          {
            header: 'Session expired',
            text:
              'To continue you have to log in again. Your current work is saved and will be loaded after you log in.',
            allowRejection: false,
            confirmationText: 'Ok',
          },
          { allowClose: false },
        )
        .then(confirmed => (confirmed ? signOut() : null));
    }

    return Promise.reject(error);
  },
);

function signOut() {
  store.dispatch('signOut');
  window.location.reload();
}

export default instance;
