<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-menu v-if="user" offset-x transition="slide-x-transition">
    <template v-slot:activator="{ on }">
      <v-list-item v-if="user" v-ripple="false" class="mb-2" v-on="on">
        <v-list-item-content>
          <v-list-item-title class="subtitle-2">
            {{ user.firstName }} {{ user.lastName }}
          </v-list-item-title>
          <v-list-item-title class="email-text">{{
            user.email
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar class="ml-1">
          <v-img :src="user.picture" max-width="32px" max-height="32px"></v-img>
        </v-list-item-avatar>
      </v-list-item>
    </template>
    <v-list dense class="pa-0">
      <v-list-item class="py-4" @click="signOut">
        <v-list-item-title>Sign out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import store from '../../../store';

export default {
  computed: {
    ...mapState(['user']),
  },
  methods: {
    signOut() {
      store.dispatch('signOut');
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list--dense .v-list-item .v-list-item__title.email-text {
  font-size: 12px;
  color: gray;
}
.email-text {
  font-size: 12px;
}
</style>
