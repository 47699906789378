<template>
  <transition name="modal-appear" appear>
    <LayoutStack :class="$style.wrapper">
      <div :class="$style.shadow" @click="$emit('close')"></div>
      <LayoutCenter>
        <div :class="$style.content"><slot></slot></div>
      </LayoutCenter>
    </LayoutStack>
  </transition>
</template>

<script>
import LayoutStack from '../../../components/layout/LayoutStack';
import LayoutCenter from '../../../components/layout/LayoutCenter';

export default {
  components: {
    LayoutStack,
    LayoutCenter,
  },
};
</script>

<style lang="scss" module>
.wrapper {
  z-index: 10;
  position: fixed;
}
.shadow {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
.content {
  display: inline-block;
  max-width: 100vw;
}

.wrapper:global(.modal-appear-enter-active) {
  $transition-duration: 0.3s;
  transition: all $transition-duration;
  .content {
    transition: all $transition-duration;
  }
  .shadow {
    transition: all $transition-duration;
  }
}
.wrapper:global(.modal-appear-leave-active) {
  $transition-duration: 0.2s;
  transition: all $transition-duration;
  .content {
    transition: all $transition-duration;
  }
  .shadow {
    transition: all $transition-duration;
  }
}
.wrapper:global(.modal-appear-enter),
.wrapper:global(.modal-appear-leave-to) {
  .content {
    transform: scale(0.9);
    opacity: 0;
  }
  .shadow {
    opacity: 0;
  }
}
</style>
