import Vue from 'vue';

export default {
  state: {
    snackbars: [],
  },
  mutations: {
    displaySnackbar(state, params) {
      const {
        id = state.snackbars.length,
        text,
        color,
        showLoadingIcon,
        errorObject,
      } = params;

      const snack = {
        id: id,
        text,
        color,
        isActive: true,
        showLoadingIcon,
        errorObject:
          process.env.NODE_ENV === 'development' ? errorObject : undefined,
      };

      for (let i = 0; i < state.snackbars.length; i++) {
        if (state.snackbars[i].id === id) {
          Vue.set(state.snackbars, i, snack);
          return;
        }
      }

      state.snackbars.unshift(snack);
    },
    hideSnackbar(state, id) {
      getSnackbar(state.snackbars, id).isActive = false;
    },
  },
  actions: {
    setLoaderSnackbar(context, params) {
      const { text, color, promise, successText, successTimeout } = params;
      if (!promise) throw Error('promise is required');

      const snack = {
        id: context.state.snackbars.length,
        text,
        color,
        showLoadingIcon: true,
        timeout: 0,
      };

      promise
        .then(text => {
          context.dispatch('setSnackbar', {
            id: snack.id,
            text: successText || text,
            color: 'success',
            showLoadingIcon: false,
            timeout: successTimeout,
          });
        })
        .catch(err => {
          context.dispatch('setSnackbar', {
            id: snack.id,
            text: getErrorText(err),
            errorObject: err,
            color: 'error',
            showLoadingIcon: false,
          });
        });

      context.dispatch('setSnackbar', snack);
    },
    setSnackbar(context, params) {
      const {
        id = context.state.snackbars.length,
        timeout = 5000,
        text,
        color,
        showLoadingIcon,
        errorObject,
      } = params;

      context.commit('displaySnackbar', {
        id,
        text,
        color,
        showLoadingIcon,
        errorObject,
      });

      if (timeout !== 0) {
        setTimeout(() => {
          if (getSnackbar(context.state.snackbars, id).isActive) {
            context.commit('hideSnackbar', id);
          }
        }, timeout);
      }
    },
  },
  getters: {
    activeSnackbars(state) {
      return state.snackbars.filter(sb => sb.isActive);
    },
  },
};

function getSnackbar(snackbars, id) {
  return snackbars.filter(s => s.id === id)[0];
}

function getErrorText(error) {
  if (!error) return 'Error not found.';
  if (
    ((error.response || {}).data || {}).message &&
    (error.response || {}).status
  ) {
    return `${error.response.status}: ${error.response.data.message}`;
  } else if (
    (error.response || {}).status &&
    ((error.response || {}).data || {}).message
  ) {
    return `${error.response.status}: ${error.response.data.message}`;
  } else if (
    (error.response || {}).status &&
    (error.response || {}).statusText
  ) {
    return `${error.response.status}: ${error.response.statusText}`;
  }
  return 'An unknown error occurred.';
}
