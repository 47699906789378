import Vue from 'vue';
import VueRouter from 'vue-router';

import signInGuard from './router-middleware/signInGuard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/hjelpesiden/countries',
    name: 'HjelpesidenCountries',
    component: () =>
      import(
        /* webpackChunkName: "Hjelpesiden/Countries" */ './views/Hjelpesiden/Countries'
      ),
  },
  {
    path: '/hjelpesiden/categories',
    name: 'HjelpesidenCategories',
    component: () =>
      import(
        /* webpackChunkName: "Hjelpesiden/Categories" */ './views/Hjelpesiden/Categories'
      ),
  },
  {
    path: '/categories',
    name: 'categories',
    component: () =>
      import(/* webpackChunkName: "categories" */ './views/Categories'),
  },
  {
    path: '/subcategories',
    name: 'subcategories',
    component: () =>
      import(/* webpackChunkName: "subcategories" */ './views/Subcategories'),
  },
  {
    path: '/countries',
    name: 'countries',
    component: () =>
      import(/* webpackChunkName: "countries" */ './views/Countries'),
  },
  {
    path: '/series',
    name: 'series',
    component: () => import(/* webpackChunkName: "series" */ './views/Series'),
  },
  {
    path: '/livsmestring/education-programmes',
    name: 'livsmestring education programmes',
    component: () =>
      import(
        /* webpackChunkName: "education-programmes" */ './views/livsmestring/EducationProgrammes'
      ),
  },
  {
    path: '/livsmestring/grades',
    name: 'livsmestring grades',
    component: () =>
      import(
        /* webpackChunkName: "education-programmes" */ './views/livsmestring/Grades'
      ),
  },
  {
    path: '/livsmestring/subjects',
    name: 'livsmestring subjects',
    component: () =>
      import(
        /* webpackChunkName: "livsmestring subjects" */ './views/livsmestring/Subjects'
      ),
  },
  {
    path: '/livsmestring/learning-outcomes',
    name: 'livsmestring learning outcomes',
    component: () =>
      import(
        /* webpackChunkName: "livsmestring learning outcomes" */ './views/livsmestring/LearningOutcomes'
      ),
  },
  {
    path: '/story-import-tool',
    name: 'story import tool',
    component: () =>
      import(/* webpackChunkName: "story-importer" */ './views/StoryImporter'),
  },
  {
    path: '/crud',
    name: 'CRUD',
    component: () => import(/* webpackChunkName: "CRUD" */ './views/CRUD'),
    children: [
      {
        path: ':type',
        name: 'CRUDListItems',
        component: () =>
          import(
            /* webpackChunkName: "CRUD/ListItems" */ './views/CRUD/ListItems'
          ),
      },
      {
        path: ':type/:id',
        name: 'CRUDUpdateItem',
        component: () =>
          import(
            /* webpackChunkName: "CRUD/UpdateItem" */ './views/CRUD/UpdateItem'
          ),
      },
      {
        path: ':type/create/new',
        name: 'CRUDCreateItem',
        component: () =>
          import(
            /* webpackChunkName: "CRUD/CreateItem" */ './views/CRUD/CreateItem'
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach(signInGuard);

router.afterEach(to => sessionStorage.setItem('lastPath', to.path));

export default router;
