/**
 * The modal loader plugin allows loading a modal/component that is shown on top
 * of all other components and that exposes a promise based api for interacting
 * with that component.
 *
 * In order to install this component, use the Vue.use() global method
 * like shown in this guide: https://vuejs.org/v2/guide/plugins.html
 * In addition, the ModalLoader component should be added as a top level
 * component. The ModalLoader communicates with the modal plugin and allows
 * loading the component on the root level - or wherever the ModalLoader is placed.
 * Only one ModalLoader component can exist.
 */

import ModalLoader from './components/ModalLoader';
import ModalConfirmation from './components/ModalConfirmation';

let _modalLoader;

export const registerModalLoader = modalWindow => {
  if (_modalLoader) throw Error('Only one ModalLoader can exist.');
  _modalLoader = modalWindow;
};

export const unregisterModalLoader = () => {
  _modalLoader = null;
};

export const $modal = {
  /**
   * Loads any component. A modal component must emit a 'resolve' event with the
   * data which the components promise should be resolved with.
   *
   * @param component
   * @param props - Properties passed to the modal
   * @param options - Options for the ModalLoader
   * @param { boolean=true } options.allowClose - Whether to allow closing the modal without it resolving. The promise will resolve to null.
   * @returns {*|Promise<any>}
   */
  loadModal(component, props, options) {
    const promise = _modalLoader.setChild(component, props);
    _modalLoader.configure(options);
    return promise;
  },
  /**
   * Loads a pre-defined simple modal that asks for confirmation. It will resolve
   * to either true or false to indicate whether confirmation was given or not.
   *
   * @param props
   * @param { string } props.header
   * @param { string } props.text
   * @param { string=Yes } props.confirmationText
   * @param { string=No } props.rejectionText
   * @param { boolean } props.allowRejection
   * @param options
   * @returns {*|Promise<any>}
   */
  loadConfirmation(props, options) {
    return $modal.loadModal(ModalConfirmation, props, options);
  },
  /**
   * Check whether the modal plugin is properly set up and ready for use.
   * @returns { boolean }
   */
  get isReady() {
    return !!_modalLoader;
  },
};

export default {
  ModalLoader,
  install(Vue) {
    Vue.prototype.$modal = $modal;
  },
};
