<template>
  <v-app v-if="!!authToken">
    <TheSideBar />
    <v-content>
      <router-view />
    </v-content>
    <TheSnackbarDisplay />
    <modal-loader />
  </v-app>
</template>

<script>
import TheSideBar from './components/globals/TheSideBar';
import TheSnackbarDisplay from './components/globals/TheSnackbarDisplay';

import { mapState } from 'vuex';

export default {
  components: {
    TheSideBar,
    TheSnackbarDisplay,
  },
  computed: {
    ...mapState(['authToken']),
  },
};
</script>
