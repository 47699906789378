<template>
  <div class="center-object__wrapper">
    <div class="center-object">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutCenter',
};
</script>

<style scoped lang="scss">
.center-object {
  &,
  &__wrapper {
    pointer-events: none;
  }
  &__wrapper {
    display: table;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  display: table-cell;
  vertical-align: middle;
  * {
    pointer-events: auto;
  }
}
</style>
