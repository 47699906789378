import axios from '../utils/api-axios';
import store from '../store';

if (!process.env.VUE_APP_API_BASE_URL) {
  throw new Error(
    'Missing required environment variable: VUE_APP_API_BASE_URL.',
  );
}

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default (to, from, next) => {
  const { user, authToken } = store.state;
  const { code } = to.query;

  if (!authToken && !code) {
    requestSignIn();
  } else if (!authToken && code) {
    fetchAuthToken(code)
      .then(storeAuthToken)
      .then(() => next(sessionStorage.getItem('lastPath') || '/'))
      .catch(err => {
        abortSignIn(err);
        next('/');
      });
  } else if (authToken && !user) {
    fetchUser()
      .then(storeUser)
      .then(() => next())
      .catch(err => {
        abortSignIn(err);
      });
  } else {
    next();
  }
};

function requestSignIn() {
  window.location.replace(
    API_BASE_URL + '/cms-users-service/authorization/sign-in',
  );
}

async function fetchAuthToken(code) {
  return (
    await axios.get('/cms-users-service/authorization/token?code=' + code)
  ).data;
}

function abortSignIn(error) {
  store.dispatch('signOut');
  showErrorAlert(error);
}

function showErrorAlert(error) {
  const response = (error || {}).response || {};
  const status = response.status;
  switch (status) {
    case 401:
      alert('Sign in failed.\n\n' + (response.data || {}).message);
      break;
    default:
      prompt(
        'Oops! An error occurred while signing you in. Please copy the text below and send it to the tech department.',
        JSON.stringify(error),
      );
      break;
  }
}

function storeAuthToken(token) {
  store.commit('setAuthToken', token);
}

function fetchUser() {
  return axios.get('/cms-users-service/users/me').then(result => result.data);
}

function storeUser(user) {
  store.commit('setUser', user);
}
