import Vue from 'vue';
import Vuex from 'vuex';

import snackbar from './modules/snackbar';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(sessionStorage.getItem('user')),
    authToken: sessionStorage.getItem('authToken'),
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      sessionStorage.setItem('user', JSON.stringify(user));
    },
    setAuthToken(state, authToken) {
      state.authToken = authToken;
      sessionStorage.setItem('authToken', authToken);
    },
  },
  actions: {
    signOut(context) {
      context.commit('setUser', '');
      context.commit('setAuthToken', '');
    },
  },
  modules: { snackbar },
});
