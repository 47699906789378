import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import DatetimePicker from 'vuetify-datetime-picker';

import modal from './plugins/modal-plugin';

Vue.config.productionTip = false;

Vue.use(modal);
Vue.use(DatetimePicker);

new Vue({
  router,
  store,
  vuetify,
  modal,
  render: h => h(App),
}).$mount('#app');
