<template>
  <v-navigation-drawer
    :value="value"
    app
    width="192"
    permanent
    :class="$style.self"
    @input="$emit('input', $event)"
  >
    <v-list dense shaped>
      <v-list-item>
        <span class="body-1">THA Admin Panel</span>
      </v-list-item>
      <div
        v-for="(group, groupIndex) in accessibleLinkGroups"
        :key="groupIndex"
        class="my-6"
      >
        <v-row class="pl-5 mb-0">
          <v-icon>{{ group.icon }}</v-icon>
          <v-subheader>{{ group.label }}</v-subheader>
        </v-row>
        <v-list-item
          v-for="item in group.links"
          :key="item.title"
          :to="item.path"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <template v-slot:append>
      <UserInfo />
    </template>
  </v-navigation-drawer>
</template>

<script>
import UserInfo from './UserInfo';
import { mapMutations, mapState } from 'vuex';

export default {
  components: {
    UserInfo,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    linkGroups: [
      {
        label: 'Stories',
        icon: 'mdi-movie',
        requireMinimumOneRole: ['Story Editor', 'Story Admin', 'Admin'],
        links: [
          { name: 'Stories', path: '/crud/stories' },
          { name: 'Categories', path: '/categories' },
          { name: 'Subcategories', path: '/subcategories' },
          { name: 'Countries', path: '/countries' },
          { name: 'Series', path: '/series' },
          {
            name: 'Education programmes',
            path: '/livsmestring/education-programmes',
          },
          { name: 'Grades', path: '/livsmestring/grades' },
          {
            name: 'Learning outcomes',
            path: '/livsmestring/learning-outcomes',
          },
          { name: 'Subjects', path: '/livsmestring/subjects' },
        ],
      },
      {
        label: 'Hjelpesiden',
        icon: 'mdi-help-network',
        requireMinimumOneRole: [
          'Hjelpesiden Editor',
          'Hjelpesiden Admin',
          'Admin',
        ],
        links: [
          { name: 'Services', path: '/crud/services' },
          { name: 'Categories', path: '/hjelpesiden/categories' },
          { name: 'Countries', path: '/hjelpesiden/countries' },
        ],
      },
      {
        label: 'Management',
        icon: 'mdi-account-supervisor',
        requireMinimumOneRole: ['Manager', 'Admin'],
        links: [{ name: 'Users', path: '/crud/users' }],
      },
      {
        label: 'Administration',
        icon: 'mdi-account-cog',
        requireMinimumOneRole: ['Admin'],
        links: [
          { name: 'Roles', path: '/crud/roles' },
          { name: 'Story Import Tool', path: '/story-import-tool' },
        ],
      },
    ],
  }),
  computed: {
    ...mapState(['user']),
    accessibleLinkGroups() {
      return this.linkGroups.filter(lg => {
        const roles = ((this.user || {}).roles || [])
          .map(role => role.name)
          .filter(role => (lg.requireMinimumOneRole || []).includes(role));

        return roles.length > 0;
      });
    },
  },
  methods: {
    ...mapMutations(['setAuthToken']),
  },
};
</script>

<style lang="scss" module>
:global(.v-navigation-drawer).self {
  background-color: $theme-color-surface;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
}
</style>
