import Vue from 'vue';
import ModalWindow from './ModalWrapper';
import { registerModalLoader, unregisterModalLoader } from '../index';

export default Vue.component('ModalLoader', {
  data() {
    return {
      childComponentClass: null,
      childProps: {},
      resolvePromise: null,
      rejectPromise: null,
      config: this.getDefaultConfig(),
    };
  },
  mounted() {
    registerModalLoader(this);
  },
  beforeDestroy() {
    unregisterModalLoader();
  },
  methods: {
    setChild(component, childProps) {
      if (this.childComponentClass) {
        this.rejectPromise('The modal was replaced');
      }

      this.childComponentClass = component;
      if (childProps) this.childProps = childProps;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    configure(options) {
      this.$set(this, 'config', { ...this.config, ...options });
    },
    onResolve(value) {
      this.resolvePromise(value);
      this.reset();
    },
    handleWrapperInitiatedClose() {
      if (!this.config.allowClose) return;
      if (this.$refs.child && this.$refs.child.beforeCloseModal) {
        this.$refs.child.beforeCloseModal();
      } else {
        this.resolvePromise(null);
      }
      this.reset();
    },
    reset() {
      this.childComponentClass = null;
      this.childProps = null;
      this.resolvePromise = null;
      this.rejectPromise = null;
      this.$set(this, 'config.ts.js', this.getDefaultConfig());
    },
    getDefaultConfig() {
      return {
        allowClose: true,
      };
    },
  },
  render(createElement) {
    if (!this.childComponentClass) return null;

    return createElement(
      ModalWindow,
      {
        on: {
          close: this.handleWrapperInitiatedClose,
        },
      },
      [
        createElement(this.childComponentClass, {
          props: this.childProps,
          on: {
            resolve: this.onResolve,
          },
          ref: 'child',
        }),
      ],
    );
  },
});
