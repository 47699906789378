<template>
  <v-card class="pa-3" :class="$style.self">
    <v-container>
      <v-row dense>
        <v-col>
          <div class="title">{{ header }}</div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <div class="body1">{{ text }}</div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="allowRejection">
          <v-btn text width="100%" @click="reject">{{ rejectionText }}</v-btn>
        </v-col>
        <v-col>
          <v-btn
            depressed
            :class="$style.btnConfirm"
            width="100%"
            @click="confirm"
            >{{ confirmationText }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    confirmationText: {
      type: String,
      default: 'Yes',
    },
    rejectionText: {
      type: String,
      default: 'No',
    },
    allowRejection: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    confirm() {
      this.$emit('resolve', true);
    },
    reject() {
      this.$emit('resolve', false);
    },
  },
};
</script>

<style lang="scss" module>
:global(.v-card).self {
  max-width: 300px;
  text-align: left;
  display: inline-block;
}
.btnConfirm {
  margin-left: auto;
}
</style>
